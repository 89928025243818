var __jsx = React.createElement;
import React from 'react';
var OrPebble = function OrPebble(_ref) {
  var color = _ref.color,
    width = _ref.width,
    height = _ref.height;
  return __jsx("svg", {
    version: "1.1",
    viewBox: "0 0 64 62",
    xmlns: "http://www.w3.org/2000/svg",
    width: width,
    height: height
  }, __jsx("title", null, "Or Pebble Icon"), __jsx("desc", null, "Or Pebble Icon"), __jsx("g", {
    fill: "none",
    fillRule: "evenodd"
  }, __jsx("g", {
    transform: "translate(-156 -1129)"
  }, __jsx("g", {
    transform: "translate(156 1129)"
  }, __jsx("path", {
    d: "m1.1917 24.128c-11.603 50.633 65.426 44.583 62.322 12.556-3.2221-30.295-55.071-58.315-62.322-12.556z",
    fill: color
  }), __jsx("path", {
    d: "m29.496 28.592v5.928c0 3.168-2.184 5.16-5.4 5.16-3.192 0-5.376-1.992-5.376-5.16v-5.928c0-3.168 1.992-5.16 5.376-5.16 3.216 0 5.4 1.992 5.4 5.16zm-8.304 0v5.928c0 1.728 1.056 2.928 2.904 2.928s2.904-1.2 2.904-2.928v-5.928c0-1.728-1.056-2.928-2.904-2.928s-2.904 1.2-2.904 2.928zm22.224 10.848h-2.712l-2.472-6c-0.12 0.024-0.264 0.024-0.408 0.024h-2.568v5.976h-2.472v-15.768h5.208c3.12 0 5.376 1.896 5.376 4.824 0 2.04-1.056 3.6-2.712 4.368l2.76 6.576zm-5.64-13.584h-2.52v5.448h2.52c1.872 0 3.072-1.08 3.072-2.784 0-1.632-1.2-2.664-3.072-2.664z",
    fill: "#323232"
  })))));
};
OrPebble.defaultProps = {
  color: '#BFF5F3',
  width: '70px',
  height: '68px'
};
export default OrPebble;
import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import React from "react";
var __jsx = React.createElement;
import { Fragment } from 'react';
import Slider from 'react-slick';
import { responsiveQuery } from "../../../constants/css";
import MediaQuery from 'react-responsive';
import SmoothCarouselMobile from "./SmoothCarouselMobile";
var SmoothCarousel = function SmoothCarousel(_ref) {
  var _ref$slickSetting = _ref.slickSetting,
    slickSetting = _ref$slickSetting === void 0 ? {} : _ref$slickSetting,
    _ref$className = _ref.className,
    className = _ref$className === void 0 ? '' : _ref$className,
    _ref$updateRef = _ref.updateRef,
    updateRef = _ref$updateRef === void 0 ? function (ref) {} : _ref$updateRef,
    children = _ref.children,
    marginRight = _ref.marginRight,
    sidePadding = _ref.sidePadding,
    noPadding = _ref.noPadding;
  return __jsx(Fragment, null, __jsx(MediaQuery, {
    query: responsiveQuery.mobileAndTablet
  }, __jsx(SmoothCarouselMobile, {
    className: className,
    marginRight: marginRight,
    sidePadding: sidePadding,
    noPadding: noPadding
  }, children)), __jsx(MediaQuery, {
    query: responsiveQuery.tabletAndDesktop
  }, __jsx(Slider, _extends({}, slickSetting, {
    className: className,
    ref: function ref(arg) {
      return updateRef(arg);
    }
  }), children)));
};
export default SmoothCarousel;
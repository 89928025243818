import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from 'react';
var InfoIcon = function InfoIcon(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10Zm8-10c0-4.415-3.585-8-8-8s-8 3.585-8 8 3.585 8 8 8 8-3.585 8-8Zm-9-2v8h2v-8h-2Zm0-4v2h2V6h-2Z",
    fill: "#323232"
  }), __jsx("mask", {
    id: "a",
    style: {
      maskType: 'alpha'
    },
    maskUnits: "userSpaceOnUse",
    x: 2,
    y: 2,
    width: 20,
    height: 20
  }, __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10Zm8-10c0-4.415-3.585-8-8-8s-8 3.585-8 8 3.585 8 8 8 8-3.585 8-8Zm-9-2v8h2v-8h-2Zm0-4v2h2V6h-2Z",
    fill: "#fff"
  })));
};
export default InfoIcon;
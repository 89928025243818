var __jsx = React.createElement;
import React from 'react';
var PlusPebble = function PlusPebble(_ref) {
  var color = _ref.color,
    width = _ref.width,
    height = _ref.height;
  return __jsx("svg", {
    version: "1.1",
    viewBox: "0 0 64 62",
    xmlns: "http://www.w3.org/2000/svg",
    width: width,
    height: height
  }, __jsx("g", {
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    fillRule: "evenodd"
  }, __jsx("path", {
    fill: color,
    d: "M1.54 22.534C-9.216 69.82 62.18 64.17 59.302 34.26 56.316 5.967 8.26-20.202 1.539 22.534z"
  }), __jsx("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M11.226 37.136v-12.2h4.014c2.416 0 4.182 1.56 4.182 3.918 0 2.451-1.766 4.03-4.182 4.03h-2.1v4.252h-1.914zm3.866-10.51H13.14v4.586h1.952c1.45 0 2.378-.928 2.378-2.32 0-1.375-.929-2.266-2.378-2.266zm8.307-1.69V35.41h5.017v1.727h-6.932v-12.2H23.4zm12.878 0h1.914v8.393c0 2.451-1.654 3.992-4.07 3.992-2.416 0-4.07-1.54-4.07-3.992v-8.392h1.915v8.392c0 1.337.78 2.265 2.155 2.265 1.357 0 2.156-.928 2.156-2.265v-8.392zm12.061 3.603h-1.896v-.335c0-1.076-.761-1.856-2.025-1.856-1.264 0-1.989.65-1.989 1.578 0 .631.428 1.225 1.283 1.541l1.84.706c1.468.557 3.01 1.318 3.01 3.509 0 2.172-1.635 3.64-4.07 3.64-2.453 0-4.163-1.356-4.163-3.529v-.352h1.877v.427c0 1.114.837 1.856 2.23 1.856 1.45 0 2.25-.78 2.25-1.838 0-1.002-.67-1.522-1.673-1.894l-1.803-.705c-1.543-.594-2.62-1.541-2.62-3.231 0-1.857 1.486-3.305 3.828-3.305 2.323 0 3.921 1.355 3.921 3.528v.26z"
  })));
};
PlusPebble.defaultProps = {
  color: '#209CA6',
  width: '70px',
  height: '68px'
};
export default PlusPebble;
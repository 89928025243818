import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from 'react';
var ArrowRedRight = function ArrowRedRight(props) {
  return __jsx("svg", _extends({
    width: 7,
    height: 10,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "m.5 1 1-1 5 5-5 5-1-1 4-4-4-4Z",
    fill: "#E40000"
  }));
};
export default ArrowRedRight;
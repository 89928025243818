import styled from 'styled-components';
import { colours, fontSizes, customFonts, layoutSizes } from "../../../constants/css";
export var MiniCardContainer = styled.div.withConfig({
  displayName: "style__MiniCardContainer",
  componentId: "sc-ofmst6-0"
})(["position:relative;display:block;background-color:", ";border-radius:4px;box-shadow:0 2px 3px 0 rgba(0,0,0,0.2),0 10px 10px 0 rgba(0,0,0,0.05);flex:1;min-width:150px;max-width:150px;height:150px;margin:0 0 0.2rem 0;transition:all .15s linear;"], colours.qantasWhite);
export var MiniOfferImage = styled.div.withConfig({
  displayName: "style__MiniOfferImage",
  componentId: "sc-ofmst6-1"
})(["height:145px;background:url(", ") 50% 0% no-repeat;background-size:cover;border-top-left-radius:4px;border-top-right-radius:4px;"], function (props) {
  return props.offerImg;
});
export var MiniMerchantLogo = styled.div.withConfig({
  displayName: "style__MiniMerchantLogo",
  componentId: "sc-ofmst6-2"
})(["border-top-right-radius:4px;border-top-left-radius:4px;min-height:60px;background-image:url('", "');background-position:center;background-repeat:no-repeat;", ""], function (props) {
  return props.merchantLogo;
}, function (props) {
  return props.offerType === 'MALL' && "\n    background-size: contain;\n    height: 60px;\n    border-radius: 0;\n    margin-top: 20px;\n  ";
});
export var MiniCardBody = styled.div.withConfig({
  displayName: "style__MiniCardBody",
  componentId: "sc-ofmst6-3"
})(["background-color:", ";padding:10px 10px 0;height:150px;overflow:hidden;border-radius:4px;"], colours.qantasWhite);
export var CardDetail = styled.div.withConfig({
  displayName: "style__CardDetail",
  componentId: "sc-ofmst6-4"
})(["display:flex;align-items:stretch;justify-content:space-between;height:60px;> div{font-family:", ";font-size:", ";color:", ";display:flex;align-items:center;justify-content:space-between;text-align:center;flex-direction:column;width:100%;@media only screen and (min-width:", "){font-size:", ";}", "}"], customFonts.ciutadellaMed, fontSizes.base, colours.qantasCharcoal, layoutSizes.desktopWidth, fontSizes.small, function (props) {
  return props.offerType === 'MCO' && "\n      &:last-child {\n        display: block;\n      }\n    ";
});
export var PrevPoints = styled.span.withConfig({
  displayName: "style__PrevPoints",
  componentId: "sc-ofmst6-5"
})(["font-family:", ";font-size:", ";color:", ";text-decoration:line-through;margin-right:5px;"], customFonts.ciutadellaMed, fontSizes.small, colours.qantasRed);
export var EarnContainer = styled.div.withConfig({
  displayName: "style__EarnContainer",
  componentId: "sc-ofmst6-6"
})(["width:100%;"]);
export var PointsEarn = styled.span.withConfig({
  displayName: "style__PointsEarn",
  componentId: "sc-ofmst6-7"
})(["font-family:", ";font-size:", ";}"], customFonts.ciutadellaMed, fontSizes.base);
export var PointsTerm = styled.span.withConfig({
  displayName: "style__PointsTerm",
  componentId: "sc-ofmst6-8"
})(["font-family:", ";font-size:", ";color:", ";}"], customFonts.ciutadellaMed, fontSizes.small, colours.qantasGrey40);
export var DaysLeft = styled.div.withConfig({
  displayName: "style__DaysLeft",
  componentId: "sc-ofmst6-9"
})(["font-family:", ";font-size:", ";text-transform:uppercase;margin:12px;}"], customFonts.ciutadellaMed, fontSizes.xs);
import React from "react";
var __jsx = React.createElement;
/** @format */

import LazyLoad from 'react-lazy-load';
import { CardCallout } from "../../_global";
import { isOfferValid, pointsCalculator, merchantCardCallout } from "../../../constants/app";
import { showDaysLeft } from "../offerUtils";
import CardButton from "../../Favourites/CardButton";
import { MiniCardContainer, MiniCardBody, MiniMerchantLogo, MiniOfferImage } from "./style";
import CardDetail from "./CardDetails";
export default function MiniOfferCard(_ref) {
  var offer = _ref.offer,
    offerType = _ref.offerType,
    offerStartDate = _ref.offerStartDate,
    offerEndDate = _ref.offerEndDate,
    offerImg = _ref.offerImg,
    callout = _ref.callout,
    bonusRibbons = _ref.bonusRibbons,
    newStore = _ref.newStore,
    merchantId = _ref.merchantId,
    merchantName = _ref.merchantName,
    merchantLogo = _ref.merchantLogo,
    lazyLoad = _ref.lazyLoad,
    pointsEarn = _ref.pointsEarn,
    prevPoints = _ref.prevPoints,
    dollarSpend = _ref.dollarSpend,
    discountType = _ref.discountType,
    onClick = _ref.onClick;
  var validOffer = isOfferValid(offerStartDate, offerEndDate);
  var calloutCopy = callout ? pointsCalculator(parseInt(pointsEarn, 10), parseInt(prevPoints, 10)) : merchantCardCallout(offer || {});
  var daysLeft = showDaysLeft(offerType, offerEndDate, callout);
  return __jsx(MiniCardContainer, {
    onClick: onClick
  }, validOffer && calloutCopy && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: calloutCopy.split(' ')[0]
  }, calloutCopy), !callout && newStore && __jsx(CardCallout, {
    bonusRibbons: bonusRibbons,
    callType: "NEW"
  }, "NEW STORE"), offerImg && lazyLoad ? __jsx(LazyLoad, {
    height: 145,
    offset: 1000
  }, __jsx(MiniOfferImage, {
    offerImg: offerImg
  })) : offerImg && __jsx(MiniOfferImage, {
    offerImg: offerImg
  }), __jsx(CardButton, {
    isMini: true,
    merchantId: merchantId,
    rounded: false,
    merchantName: merchantName
  }), __jsx(MiniCardBody, null, merchantLogo && lazyLoad ? __jsx(LazyLoad, {
    height: 60,
    offset: 500
  }, __jsx(MiniMerchantLogo, {
    merchantLogo: merchantLogo,
    offerType: offerType
  })) : merchantLogo && __jsx(MiniMerchantLogo, {
    merchantLogo: merchantLogo,
    offerType: offerType
  }), __jsx(CardDetail, {
    offerType: offerType,
    prevPoints: prevPoints,
    pointsEarn: String(pointsEarn),
    dollarSpend: dollarSpend,
    discountType: discountType,
    offerEndDate: offerEndDate,
    daysLeft: daysLeft
  })));
}
export var CardSlickSettings = {
  infinite: false,
  centerMode: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  dots: false,
  arrows: false,
  draggable: true,
  responsive: [{
    breakpoint: 1100,
    settings: {
      slidesToShow: 3.5,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 960,
    settings: {
      slidesToShow: 2.4,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 2.6,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 580,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1
    }
  }]
};
export var getMcoInfo = function getMcoInfo(_ref) {
  var isVisaEnabled = _ref.isVisaEnabled,
    isVisaAmexEnabled = _ref.isVisaAmexEnabled;
  var cardType = 'Mastercard®';
  if (isVisaAmexEnabled) {
    cardType += ', Visa® or American Express card';
  } else if (isVisaEnabled) {
    cardType += ' or Visa® card';
  }
  return ["Link your ".concat(cardType), "Pay with your linked ".concat(cardType), 'Earn bonus Qantas Points'];
};
export var mallInfo = ['Start at Qantas Shopping', 'Click ‘Shop now’', 'Shop and pay as normal', 'Be rewarded'];
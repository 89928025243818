var __jsx = React.createElement;
import React from 'react';
import styled, { keyframes, css } from 'styled-components';
var hearts = "/static/icons/hearts.png";
var heartBurst = keyframes(["from{background-position:left;}to{background-position:right;}"]);
var isLiked = css(["animation:", " 0.9s steps(39) 1 forwards;"], heartBurst);
var FavouriteIconStyled = styled.div.withConfig({
  displayName: "FavouriteIcon__FavouriteIconStyled",
  componentId: "sc-zsyg9v-0"
})(["cursor:pointer;width:36px;height:36px;background-image:url('", "');background-position:left;background-repeat:no-repeat;background-size:4000%;", " @media ", "{width:46px;height:46px;}"], hearts, function (props) {
  return props.isFavourite && css(["", ""], isLiked);
}, function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
});
var FavouriteIcon = function FavouriteIcon(_ref2) {
  var onClick = _ref2.onClick,
    isFavourite = _ref2.isFavourite;
  var tooltipTitle = !isFavourite ? 'add to my favourites' : 'remove from my favourites';
  return __jsx(FavouriteIconStyled, {
    onClick: onClick,
    isFavourite: isFavourite,
    title: tooltipTitle
  });
};
FavouriteIcon.defaultProps = {
  isFavourite: false
};
export default FavouriteIcon;
import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @format */

import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import get from 'lodash';
import { ArrowIcon } from "../_global/Icons";
import SmoothCarousel from "../Carousel/SmoothCarousel";
import { commonOfferProps, commonOlmProps } from "../Offer/offerUtils";
import OfferCard from "../Offer/Card";
import MiniOfferCard from "../Offer/MiniOfferCard";
import { McoCard } from "../CardOffers/style";
import { responsiveQuery } from "../../constants/css";
import { OFFER_TYPES, flagsPropType } from "../../constants/app.js";
import NurseryCards from "../NurseryCardsCarousel/NurseryCards";
import { isChannelForBrowser } from "../../utils/sessionStorageHelper";
import { StyledCardWrapper, CardPrevArrowContainer, CardNextArrowContainer, McoContainer, HowItWorks, TextCenter, ViewAllContainer } from "./style";
import { CardSlickSettings, getMcoInfo, mallInfo } from "./utils";
var viewAllBtn = "/static/icons/icon_red_arrow_right.svg";
export default function OffersCarousel(_ref) {
  var offers = _ref.offers,
    mcoActivate = _ref.mcoActivate,
    ffn = _ref.ffn,
    flags = _ref.flags,
    bonusCampaignItems = _ref.bonusCampaignItems,
    offerType = _ref.offerType,
    noMargin = _ref.noMargin,
    noShadow = _ref.noShadow,
    categoryUrl = _ref.categoryUrl;
  var bonusRibbons = get(bonusCampaignItems, ['0', 'bonusRibbons'], []);
  var mcoOffers = [];
  var merchants = [];
  var nurseryOffers = [];
  switch (offerType) {
    case OFFER_TYPES.MCO:
      mcoOffers = offers;
      break;
    case OFFER_TYPES.MALL:
      merchants = offers;
      break;
    case OFFER_TYPES.NURSERY:
      nurseryOffers = offers;
      break;
    case OFFER_TYPES.CATEGORIES:
      merchants = offers;
      break;
    default:
      break;
  }
  var _useState = useState(0),
    activeMcoSlide = _useState[0],
    setActiveMcoSlide = _useState[1];
  var handleMcoLink = function handleMcoLink(url) {
    if (isChannelForBrowser()) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  };
  var mcoSlider = null;
  var beforeChange = function beforeChange(_, next) {
    setActiveMcoSlide(next);
  };
  var updateMcoRef = function updateMcoRef(ref) {
    mcoSlider = ref;
  };
  var mcoNext = function mcoNext() {
    mcoSlider.slickNext();
  };
  var mcoPrevious = function mcoPrevious() {
    mcoSlider.slickPrev();
  };
  var renderMco = function renderMco() {
    return mcoOffers.map(function (offer, index) {
      var commonProps = commonOfferProps(offer);
      return __jsx(McoCard, _extends({
        key: index
      }, commonProps, {
        onClick: !offer.activated ? function () {
          return mcoActivate(offer.offerIdMls, ffn);
        } : '',
        shopNow: offer.activated && offer.merchantWebsiteURL ? function () {
          return handleMcoLink(offer.merchantWebsiteURL);
        } : '',
        findStore: offer.activated && offer.merchantStoreLocatorURL ? function () {
          return handleMcoLink(offer.merchantStoreLocatorURL);
        } : '',
        lazyLoad: true,
        hasName: "CO_".concat(offer.merchantId, "_").concat(index),
        noShadow: noShadow
      }));
    });
  };
  var renderNursery = function renderNursery() {
    return nurseryOffers.map(function (offer, index) {
      return __jsx(TextCenter, {
        key: index
      }, __jsx(NurseryCards, {
        offer: offer,
        offerType: OFFER_TYPES.NURSERY,
        noShadow: noShadow
      }));
    });
  };
  var renderOLM = function renderOLM() {
    return merchants.map(function (merchant, index) {
      var commonProps = commonOlmProps(merchant);
      return __jsx(React.Fragment, {
        key: index
      }, __jsx(MediaQuery, {
        query: responsiveQuery.mobileNew
      }, __jsx(MiniOfferCard, _extends({}, commonProps, {
        bonusRibbons: bonusRibbons,
        merchantName: merchant.merchantName,
        hasFavourite: merchant.hasFavourite,
        hasName: "OM_".concat(merchant.merchantId, "_").concat(index),
        noShadow: noShadow
      }))), __jsx(MediaQuery, {
        query: responsiveQuery.largerThanMobileNew
      }, __jsx(OfferCard, _extends({}, commonProps, {
        bonusRibbons: bonusRibbons,
        merchantName: merchant.merchantName,
        hasFavourite: merchant.hasFavourite,
        hasName: "OM_".concat(merchant.merchantId, "_").concat(index),
        noShadow: noShadow
      }))));
    });
  };
  var renderCategories = function renderCategories() {
    return merchants.slice(0, 10).map(function (merchant, index) {
      var commonProps = commonOlmProps(merchant);
      if (index > 1 && (index === merchants.length - 1 || index === 9)) {
        return __jsx(React.Fragment, {
          key: index
        }, __jsx(MiniOfferCard, _extends({}, commonProps, {
          bonusRibbons: bonusRibbons,
          merchantName: merchant.merchantName,
          hasFavourite: merchant.hasFavourite,
          hasName: "OM_".concat(merchant.merchantId, "_").concat(index),
          noShadow: noShadow
        })), __jsx(ViewAllContainer, null, __jsx("a", {
          role: "link",
          href: categoryUrl
        }, __jsx("img", {
          alt: "view all",
          src: viewAllBtn
        }), __jsx("label", null, "View all"))));
      }
      return __jsx(React.Fragment, {
        key: index
      }, __jsx(MiniOfferCard, _extends({}, commonProps, {
        bonusRibbons: bonusRibbons,
        merchantName: merchant.merchantName,
        hasFavourite: merchant.hasFavourite,
        hasName: "OM_".concat(merchant.merchantId, "_").concat(index),
        noShadow: noShadow
      })));
    });
  };
  var conditionallyRenderCards = function conditionallyRenderCards() {
    switch (offerType) {
      case OFFER_TYPES.MCO:
        return renderMco();
      case OFFER_TYPES.MALL:
        return renderOLM();
      case OFFER_TYPES.NURSERY:
        return renderNursery();
      case OFFER_TYPES.CATEGORIES:
        return renderCategories();
      default:
        return [];
    }
  };
  var getHowItWorks = function getHowItWorks() {
    if (offerType === OFFER_TYPES.MCO) {
      return __jsx(HowItWorks, {
        title: "Unlock your weekly bonus points offers",
        bulletPoints: getMcoInfo(flags),
        linkUrl: "/how-it-works#card-offers"
      });
    }
    return __jsx(HowItWorks, {
      title: "Earn points when you shop online",
      bulletPoints: mallInfo,
      linkUrl: "/how-it-works"
    });
  };
  return __jsx(StyledCardWrapper, {
    noMargin: noMargin
  }, activeMcoSlide !== 0 && __jsx(CardPrevArrowContainer, {
    onClick: mcoPrevious
  }, __jsx(ArrowIcon, {
    direction: "left"
  })), __jsx(CardNextArrowContainer, {
    onClick: mcoNext
  }, __jsx(ArrowIcon, {
    direction: "right"
  })), __jsx(McoContainer, {
    wideView: true,
    noMargin: noMargin
  }, __jsx(SmoothCarousel, {
    slickSetting: _objectSpread(_objectSpread({}, CardSlickSettings), {}, {
      beforeChange: beforeChange
    }),
    updateRef: function updateRef(ref) {
      return updateMcoRef(ref);
    }
  }, getHowItWorks(), conditionallyRenderCards())));
}
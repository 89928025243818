/** @format */

import PropTypes from 'prop-types';
export var offerProps = {
  authenticated: PropTypes.bool,
  enrolled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  user: PropTypes.shape({
    authenticated: PropTypes.bool
  }),
  mcoOffers: PropTypes.arrayOf(PropTypes.shape({
    offerIdMls: PropTypes.string,
    merchantId: PropTypes.string,
    headline: PropTypes.string,
    longDescription: PropTypes.string,
    offerImageURL: PropTypes.string,
    redemptionType: PropTypes.string,
    discount: PropTypes.string,
    minTransactionAmountToRedeem: PropTypes.string,
    redeemDate: PropTypes.string,
    eventEndDate: PropTypes.string,
    eventStartDate: PropTypes.string,
    activated: PropTypes.bool,
    merchantWebsiteURL: PropTypes.string,
    merchantStoreLocatorURL: PropTypes.string,
    earnRate: PropTypes.number,
    merchantLogoURL: PropTypes.string,
    createdAt: PropTypes.string
  })),
  mcoActivate: PropTypes.func,
  ffn: PropTypes.string,
  webView: PropTypes.bool
};
export var offerPropsDefault = {
  mcoOffers: [],
  authenticated: false,
  enrolled: false,
  loading: false,
  error: {},
  mcoActivate: function mcoActivate() {
    // intentionally empty thanks sonar
  },
  ffn: '',
  webView: false,
  user: {}
};
import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
var SmoothCarouselWrapper = styled.div.withConfig({
  displayName: "SmoothCarouselMobile__SmoothCarouselWrapper",
  componentId: "sc-7nhgwy-0"
})(["overflow:hidden;padding:0 0 0.5rem 0;>div{display:flex;overflow-x:scroll;padding-bottom:1rem;margin-bottom:-17px;>div:not(:last-of-type){", "}>span{display:block;min-width:", ";}}"], function (_ref) {
  var marginRight = _ref.marginRight;
  return "margin-right: ".concat(marginRight);
}, function (_ref2) {
  var sidePadding = _ref2.sidePadding;
  return sidePadding;
});
// The spans as padding is because
// margin and padding for the last item of category are missing on many mobile device
var SmoothCarouselMobile = function SmoothCarouselMobile(_ref3) {
  var _ref3$className = _ref3.className,
    className = _ref3$className === void 0 ? '' : _ref3$className,
    children = _ref3.children,
    _ref3$marginRight = _ref3.marginRight,
    marginRight = _ref3$marginRight === void 0 ? '1rem' : _ref3$marginRight,
    _ref3$sidePadding = _ref3.sidePadding,
    sidePadding = _ref3$sidePadding === void 0 ? '1rem' : _ref3$sidePadding,
    noPadding = _ref3.noPadding;
  return __jsx(SmoothCarouselWrapper, {
    className: className,
    marginRight: marginRight,
    sidePadding: sidePadding
  }, __jsx("div", null, !noPadding && __jsx("span", {
    className: "padding"
  }), children, !noPadding && __jsx("span", {
    className: "padding"
  })));
};
export default SmoothCarouselMobile;
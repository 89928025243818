import _toConsumableArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { REDEMPTION_TYPES } from "../../constants/app";
export var sortFields = [{
  name: 'Latest',
  value: 'start-date'
}, {
  name: 'A-Z',
  value: 'a-z'
}, {
  name: 'Points per $',
  value: 'points'
}, {
  name: 'Expiring soon',
  value: 'end-date'
}];
export var getFilteredOffers = function getFilteredOffers(offers, filters, favouritesList) {
  if (!(offers && offers.length > 0)) {
    return [];
  }
  var filteredOffers = _toConsumableArray(offers);
  if (filters.redemptionType || filters.favourites) {
    filteredOffers = offers.filter(function (o) {
      var showOffer = true;
      var redemptionMatch = o.redemptionType === REDEMPTION_TYPES.BOTH || o.redemptionType === filters.redemptionType;
      var favouritesMatch = favouritesList[o.merchantId];
      if (filters.redemptionType && !filters.favourites) {
        showOffer = redemptionMatch;
      } else if (filters.favourites && !filters.redemptionType) {
        showOffer = favouritesMatch;
      } else {
        showOffer = redemptionMatch && favouritesMatch;
      }
      return showOffer;
    });
  }
  if (filters.sortOrder) {
    var sortByName = function sortByName(a, b) {
      var nameA = a.merchantName.toUpperCase();
      var nameB = b.merchantName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    };
    var sortByPoints = function sortByPoints(a, b) {
      if (b.earnRate && a.earnRate) {
        return b.earnRate - a.earnRate;
      }
      return 0;
    };
    var sortByEndDate = function sortByEndDate(a, b) {
      if (b.eventEndDate && a.eventEndDate) {
        return new Date(a.eventEndDate) - new Date(b.eventEndDate);
      }
      return 0;
    };
    var sortByStartDate = function sortByStartDate(a, b) {
      if (b.createdAt && a.createdAt) {
        return b.createdAt - a.createdAt;
      }
      return 0;
    };
    var sortFunction;
    switch (filters.sortOrder) {
      case 'a-z':
        sortFunction = sortByName;
        break;
      case 'points':
        sortFunction = sortByPoints;
        break;
      case 'end-date':
        sortFunction = sortByEndDate;
        break;
      case 'start-date':
        sortFunction = sortByStartDate;
        break;
      default:
        sortFunction = null;
    }
    if (sortFunction) {
      filteredOffers.sort(sortFunction);
    }
  }
  return filteredOffers;
};